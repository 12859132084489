<template>
	<section class="contact">
		<div class="contact__content">
			<div class="contact__col" v-if="!this.$store.state.isEn">
				<h3>PRODUCT & SERVICE DESIGN</h3>
				<span>MANAGING DIRECTOR</span>
				<p>Piotr Cegielski</p>
				<p>piotr.cegielski@hylite.pl</p>
			</div>
			<div class="contact__col">
				<h3>{{ this.$store.state.isEn ? 'ANIMATION' : 'ANIMACJA' }}  </h3>
				<span>MANAGING DIRECTOR</span>
				<p>Ewa Geruzel</p>
				<p>ewa.geruzel@hylite.pl</p>
			</div>
			<div class="contact__col" v-if="!this.$store.state.isEn">
				<h3>REKRUTACJA </h3>
				<p>jobs@hylite.pl</p>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "index contact"
}
</script>

<style scoped lang="sass">
.contact
	background: #101011
	padding-top: 85px
	padding-bottom: 106px

	&__content
		width: 1180px
		padding-left: 50px
		margin: auto
		display: flex
		justify-content: space-between

		@include view('md')
			width: 100%
			padding: 60px 33px
			text-align: center

		@include view('sm')
			width: 100%
			flex-direction: column
			gap: 4rem 0
			justify-content: flex-start
			padding: 0 33px
			text-align: left

	&__col
		display: flex
		flex-direction: column
		width: 215px

		@include view('md')
			width: 185px

		&:nth-child(1)
			h3
				padding-top: 0

		h3
			height: 60px
			padding-top: 15px
			border-bottom: 1px solid white
			font-size: 18px
			color: white
			font-weight: $weight--semi-bold
			margin-bottom: 21px

		p
			font-size: 15px
			font-weight: 300
			color: #d8d8dc
			line-height: 22px

		span
			font-size: 15px
			font-weight: $weight--semi-bold
			color: #d8d8d8
			margin-bottom: 3px
</style>
